@import "../partials/_variables";
@import "../partials/_mixins";
@import "../partials/_breadcrumb";

html {
  color: @textColor;
  font-family: @font-page;

  body {
    * {
      box-sizing: border-box;
    }

    .main {
      &-container {
        max-width: 90rem;
        margin: 0 auto;
      }
    }

    &.has-emergency {
      .breadcrumb-container {
        margin: 0.75rem auto;
      }
    }
  }
}

.body {
  &-container {
    section {
      margin: 0 auto;
      max-width: 87.5rem;
    }

    .link {
      color: @linkColor;
      text-decoration-line: underline;
    }

    .banner-container {
      padding: 1.875rem 1.25rem;
      text-align: center;

      &__title {
        color: @titleColor;
        font-family: @font-heading;
        font-style: normal;
        font-weight: bold;
        text-transform: uppercase;
        .font-16(17, 30);
        @media (min-width: @screen) {
          .font-16(22, 30);
        }
      }

      &__paragraph {
        color: @textColor;
        font-family: @font-banner;
        font-style: italic;
        font-weight: normal;
        margin: 0.5rem 0;
        text-align: center;
        .font-16(15, 20);

        &-small {
          color: @textColor;
          font-style: normal;
          font-weight: normal;
          .font-16(10, 15);
        }
      }
    }

    .roundButton {
      background: @buttonBgColor;
      border-radius: 3.75rem;
      color: white;
      cursor: pointer;

      &:hover {
        background: @buttonBgHoverColor;
      }
    }

    .btn-breadcrumb {
      background: none;
      border: 0;
      cursor: pointer;
      padding: 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
