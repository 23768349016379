.sliderBanner {
  .slider {
    &-inner {
      .item {
        margin: 0 0.625rem;
        outline: 0;
        position: relative;

        .image-cropper {
          border-radius: 4px;
          height: 636px;
          overflow: hidden;
          position: relative;
          width: 100%;

          .img {
            border-radius: 4px;
            display: block;
            max-height: 100%;
            position: absolute;
            right: 0;
            top: 0px;
          }
        }

        .wrapInformation {
          color: @color-light;
          display: inline-block;
          left: 50%;
          padding-right: 0;
          pointer-events: none;
          position: absolute;
          text-align: right;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;

          .information {
            background-color: rgba(238, 247, 247, 0.7);
            border-radius: 4px;
            color: @mainTextColor;
            display: block;
            margin: 25px auto;
            min-width: 900px;
            padding: 40px;
            position: relative;
            text-align: left;
            width: 75%;

            .title {
              font-family: @font-heading;
              font-size: 1.25em;
              margin-bottom: 0.75rem;
              margin-top: 0;
              text-transform: uppercase;
            }

            .text {
              font-size: 0.938em;
              line-height: 1em;
              margin-bottom: 0;
              max-width: 75%;
            }
          }
        }
      }
    }
  }

  .destinationPush {
    .information {
      @media (max-width: 1023px) {
        min-width: 0 !important;
        width: 80% !important;
      }
    }
  }
}
