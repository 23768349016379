@import '../../partials/_variables';

/**
 * Slick partial styles
 */
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  user-select: none;

  .slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: hand;
    }

    .slick-track {
      display: block;
      left: 0;
      position: relative;
      top: 0;

      &:after,
      &:before {
        content: '';
        display: table;
      }

      &:after {
        clear: both;
      }

      .slick-loading & {
        visibility: hidden;
      }

      .slick-slide {
        float: left;
        height: 100%;
        max-height: 44.375rem;
        min-height: 0.063rem;

        &.slick-loading img {
          display: none;
        }

        &.dragging img {
          pointer-events: none;
        }

        .slick-initialized & {
          display: block;
        }

        .slick-loading & {
          visibility: hidden;
        }

        .slick-vertical & {
          border: 0.063rem solid transparent;
          display: block;
          height: auto;
        }
      }
    }
  }

  .slick-arrow {
    &.slick-hidden {
      display: none;
    }

    &.slick-disabled {
      cursor: default;
      opacity: 0.1;
    }
  }

  .slick-next,
  .slick-prev {
    background: transparent;
    border: 0;
    bottom: -1.563rem;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 2.375rem;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: auto;
    transform: translate(0, -50%);
    width: 2.375rem;
    z-index: 2;

    &:focus {
      outline: 0.125rem solid @linkColor;
    }

    &:hover {
      border-radius: 2.375rem;
      box-shadow: 0 0 0 0.063rem @titleColor;
    }

    &.slick-disabled,
    &.slick-disabled:before {
      opacity: 0.1;
    }

    &:before,
    &:after {
      content: '';
      display: block;
      height: 100%;
      margin: 0 auto;
      position: relative;
      width: 0.75rem;
    }
  }

  .slick-next {
    right: 0.938rem;

    @media (min-width: @screen) {
      right: 16.875rem;
    }

    &:before {
      background-position: -0.75rem 50%;
    }
  }

  .slick-prev {
    left: 0.938rem;

    @media (min-width: @screen) {
      left: 16.875rem;
    }

    &:before {
      background-position: 0 50%;
    }
  }

  .slick {
    &-next,
    &-prev,
    &-next:hover,
    &-prev:hover,
    &-prev:focus,
    &-next:focus {
      background: url('/assets/images/common/spritesheet.png') no-repeat;
    }

    &-prev,
    &-prev:focus,
    &-prev:hover {
      background-position: -6.563rem -2.563rem;
    }

    &-next,
    &-next:focus,
    &-next:hover {
      background-position: -3.313rem -3.375rem;
    }
  }

  /* Pause/Play Button*/
  .slick-pause {
    background: @textColor;
    border: 0.063rem solid @textColor;
    border-radius: 2.5rem;
    bottom: -1.563rem;
    cursor: pointer;
    display: inline-block;
    height: 2.375rem;
    left: 13rem;
    padding: 0;
    position: absolute;
    transform: translate(0, -50%);
    width: 2.375rem;
    z-index: 10;

    @media (max-width: @screen) {
      display: none;
    }

    &:hover,
    &:focus {
      background: @buttonBgColor;
    }

    &:focus {
      outline: 0.125rem solid @linkColor;
    }

    &.slick--paused {
      overflow: hidden;
    }

    &.slick--paused:after {
      border-bottom: 0.5rem solid transparent;
      border-left: 0.875rem solid @color-light;
      border-right: 0.875rem solid transparent;
      border-top: 0.5rem solid transparent;
      content: '';
      display: block;
      left: 0.75rem;
      position: absolute;
      top: 0.563rem;
    }

    &.slick--playing:before,
    &.slick--playing:after {
      background: @color-light;
      content: '';
      display: block;
      height: 1rem;
      position: absolute;
      top: 0.563rem;
      width: 0.188rem;
    }

    &.slick--playing:before {
      left: 0.75rem;
    }

    &.slick--playing:after {
      right: 0.75rem;
    }
  }

  .foo {
    align-items: center;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    height: auto;
    justify-content: center;
    margin: auto;
    padding: 1.25rem 3.75rem 0 3.75rem;
    width: 100%;

    @media (min-width: @screen) {
      width: 60%;
    }

    span {
      margin: 0 0.188rem;
    }

    &::before,
    &::after {
      background: @disabledColor;
      content: '';
      display: block;
      flex: 1;
      height: 0.063rem;
      margin: 0 1rem;
    }
  }
}
.slick-initialized {
  opacity: 1;
  visibility: visible;
  .slick-slide {
    display: block;
  }
  &.loading:after{
    display: none;
  }
}
.slick-slide.slick-loading {
  img {
    display: none;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
  img {
    display: block;
  }
}

.loading:after {
  animation: spin 2s linear infinite;
  border-radius: 50%;
  border: 4px solid #068484;
  border-top: 4px solid transparent;
  content: '';
  display: block;
  height: 40px;
  width: 40px;
  margin: auto;
  left: 0;
  right: 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
