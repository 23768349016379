@couleur-primaire: #252339;
@couleur-fond-description: #e9e8f2;
@famille-police: "Roboto", sans-serif;
@mobile-breakpoint: 1024px;
@tablet-breakpoint: 1320px;

.oneBlocRightPics {
  margin: 80px 0.625rem 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @couleur-primaire;

  &__picture {
    flex: 1 0 calc(50% - 4rem);
    max-width: 50%;
    margin: 0 0 0 4rem;

    &__img {
      max-width: 600px;
    }
  }

  &__description {
    flex: 1 0 calc(50% - 2rem);
    background-color: @couleur-fond-description;
    padding: 2rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 2rem 0 0;

    h2 {
      font-size: 44px;
      font-weight: bold;
      margin: 10px 0 20px 0;
    }

    p {
      line-height: 1.8rem;
    }
  }

  &__cta {
    padding: 2rem 0;
    .roundButton {
      border: 2px solid @couleur-primaire;
      border-radius: 20px;
      padding: 10px 20px;
      text-decoration: none;
      color: @couleur-primaire;
      background: transparent;
      cursor: pointer;
      margin-right: 5px;

      &--login {
        background-color: transparent;
      }

      &--register {
        background-color: @couleur-primaire;
        color: white;
      }

      &:hover {
        background-color: @couleur-primaire;
        color: white;
        opacity: 0.9;
      }
    }
  }
}

@media (max-width: @mobile-breakpoint) {
  .oneBlocRightPics {
    flex-direction: column;
    align-items: stretch;

    &__cta {
      .roundButton {
        padding: 10px;
      }
    }

    &__picture,
    &__description {
      background-color: transparent;
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0 auto;
    }

    &__picture {
      text-align: center;
      padding: 0 2rem;

      &__img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: @tablet-breakpoint) {
  .oneBlocRightPics {
    &__description {
      flex: 1 0 calc(50% - 6rem);

      h2 {
        font-size: 34px;
      }
    }
  }
}
