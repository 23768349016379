@import '../../partials/_common';

.main-container {
  a:focus,
  button:focus,
  input[type='text']:focus {
    box-shadow: 0 0 0 1px @color-light, 0 0 0 3px @linkColor;
    outline: 0rem;
  }

  .btn-wrapper {
    margin: 0rem;
    text-align: center;

    .btn-md {
      text-decoration: none;
      text-transform: none;

      &.lighter-blue {
        background-color: @buttonBgColor;
        border: 1px solid transparent;
        border-radius: 1.875rem;
        color: @color-light;
        font-family: @font-page;
        font-size: 1rem;
        padding: 0.625rem 1.25rem;
        text-align: center;
      }
    }
  }

  .section {
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;

    @media (max-width: @tablet) {
      padding: 1rem;
    }

    &__bloc-title {
      color: @textColor;
      font-family: @font-heading;
      font-size: 1.375rem;
      padding: 0.75rem 0rem;
      text-align: center;
      text-transform: uppercase;
    }

    &__bloc-description {
      font-family: @font-page;
      font-size: 1em;
      font-weight: 600;
      margin: auto;
      max-width: 56.25rem;
      text-align: center;
    }
  }
}
