.sliderUsp {
  text-align: center;

  @media (max-width: @tablet) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &__slider {
    &.slick-slider {
      margin: 2.5rem 0rem;

      .card-item {
        padding: 1.25rem;
        text-align: center;

        &__icon {
          background: @lightBg;
          border-radius: 80px;
          margin: auto;
          max-height: 80px;
          max-width: 80px;

          .icon {
            height: 80px;
            padding: 0.938rem;
            width: 80px;
          }
        }

        &__title {
          display: block;
          font-weight: bold;
          text-transform: uppercase;
        }

        &__link {
          color: @linkColor;
          display: block;
          font-size: 0.875rem;
          font-weight: 700;
          line-height: 1.3;
          margin-top: 0.5rem;

          &:hover {
            text-decoration: none;
          }

          &:focus {
            background: @linkColor;
            box-shadow: none;
            color: @color-light;
          }
        }
      }

      @media (min-width: @tablet) {
        .foo {
          display: none;
        }
      }
    }
  }
}
