.engine__home {
  background-image: url('/imagerie/home/bg_search_engine.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  font-family: @font-page;
  margin-top: 0;
  padding-top: 3rem;

  @media (max-width: @mobile-medium) {
    background: none;
    padding-left: 0;
    padding-right: 0;
  }

  @media (min-width: @mobile-medium) {
    padding-bottom: 3.125rem;
  }

  .engine {
    &__form {
      background: @mainBgColor;
      padding: 1.8rem;

      @media (max-width: @mobile-medium) {
        padding-top: 0;
      }
    }

    &__container {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
    }

    &__title {
      color: @color-light;
      font-size: 3rem;
      text-align: center;

      @media (max-width: @mobile-medium) {
        color: @textColor;
        font-size: 2.2rem;
      }
    }

    &__block {
      &-nav {
        display: flex;
        margin-bottom: 40px;
        width: 100%;

        @media (max-width: @mobile-medium) {
          margin-bottom: 30px;
        }

        .engine__field {
          @media (max-width: @mobile-medium) {
            width: 100%;
          }
        }
      }

      &-identification {
        align-items: baseline;
        border-radius: 4px 4px 0 0;
        flex-wrap: wrap;
        padding: 0 20px 20px;
        width: 100%;

        @media (min-width: @mobile-medium) {
          display: flex;
        }

        @media (min-width: @mobile-medium) {
          padding: 20px;
        }

        &_radio {
          &-item {
            display: inline-flex;

            @media (min-width: @mobile-medium) {
              display: flex;
            }

            &-0 {
              margin-right: 1rem;
            }

            input[type="radio"] {
              opacity: 0;
              position: absolute;

              &:checked + label {
                background: @mainBgColor;
                border: 1px solid @greenB2BColor;

                span {
                  color: @greenB2BColor;
                }
              }
            }

            label {
              background: @mainBgColor;
              border: 1px solid @borderInputColor;
              border-radius: 6px;
              cursor: pointer;
              display: inline-flex;
              margin: 0;
              min-height: auto;
              padding: 0.625rem;

              @media (max-width: @mobile-medium) {
                margin: auto 0;
                min-height: auto;
              }

              span {
                color: @borderInputColor;
              }
            }
          }
        }
      }

      &-main {
        border: 1px solid @disabledColor;
        border-radius: 0 0 4px 4px;
        display: none;
        margin-top: -1px;
        padding: 20px;
        width: 100%;

        &.open {
          display: block;
        }

        @media (min-width: @mobile-medium) {
          display: block;
          margin-bottom: 20px;

          &.open {
            display: block;
          }
        }

        @media (min-width: @screen) {
          .colWidth(12);
          align-items: flex-end;
          justify-content: space-between;
          display: flex;

          &.open {
            display: flex;
          }
        }

        @media (min-width: @mobile-medium) {
          padding: 20px 20px 60px;
        }
      }

      &-special_rates {
        align-self: normal;
        display: none;
        flex-wrap: wrap;
        width: 100%;
        display: none;

        &.open {
          display: flex;
        }

        @media (min-width: @mobile-medium) {
          display: flex;

          &.open {
            display: flex;
          }
        }
      }

      &-additional_criteria {
        z-index: 1;
        @media (min-width: @mobile-medium) {
          position: absolute;
          right: 30px;
          top: 115px;
          hr {
            display: none;
          }

          .engine__link-advanced_search {
            padding: 0;
          }
        }
      }
    }

    &__field {
      &-reserverType {
        &_wrapper {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 16px;

          @media (max-width: @mobile-medium) {
            padding: 16px 0 0 0;
          }

          label {
            flex: 100%;
          }
        }
      }

      &-reserverId {
        padding-left: 60px;

        &_wrapper {
          position: relative;

          @media (min-width: @tablet) {
            margin-right: 20px;
            max-width: calc((100% / 2) - 1.25rem);
            padding: 0;
          }

          &::after {
            border-right: 1px solid @borderInputColor;
            bottom: 0;
            content: attr(data-content);
            left: 0;
            min-width: 50px;
            padding: 16px 0;
            position: absolute;
            text-align: center;

            @media (min-width: @mobile-medium) and (max-width: @screen) {
              bottom: 0.625rem;
            }
          }
        }
      }

      &-date {
        padding: 0 0 20px 0;
        @media (min-width: @mobile-small) {
          padding: 0;
        }

        &_wrapper {
          @media (min-width: @mobile-small) {
            display: flex;
          }

          @media (min-width: @screen) {
            .colWidth(4, 12);
            margin: 0 20px;
          }
        }

        &Out {
          @media (min-width: @mobile-small) {
            margin-left: 20px;
          }
        }
      }

      &-destination {
        &_wrapper {
          @media (min-width: @screen) {
            .colWidth(4, 12);
          }
        }
      }

      &-rooms {
        &_wrapper {
          @media (min-width: @screen) {
            .colWidth(4, 12);
          }
        }
      }

      &-toggle {
        &_wrapper {
          width: 100%;
        }
      }

      &-customerCode {
        &_wrapper {
          @media (min-width: @mobile-small) {
            .colWidth(7, 12);
          }

          @media (min-width: @screen) {
            .colWidth(4, 10);
            margin: 0 10px;
          }
        }
      }

      &-accessCode {
        &_wrapper {
          @media (min-width: @mobile-small) {
            .colWidth(5, 12);
          }

          @media (min-width: @screen) {
            .colWidth(2, 10);
            margin-left: 10px;
          }
        }
      }

      &-loyaltyCard {
        &_wrapper {
          @media (min-width: @tablet) {
            .colWidth(6);
            margin-right: 20px;
            max-width: calc((100% / 2) - 1.25rem);
            padding: 0;
          }
        }
      }

      &-codeStar {
        &_wrapper {
          .colWidth(12);

          @media (min-width: @mobile-medium) {
            .colWidth(6);
            margin-right: 20px;
            padding: 0;
          }

          @media (min-width: @tablet) {
            width: calc((100% / 2) - 1.25rem);
          }
        }
      }

      &-accessCodeStar {
        &_wrapper {
          .colWidth(12);

          @media (min-width: @mobile-medium) {
            .colWidth(6);
            padding: 0;
          }

          @media (min-width: @tablet) {
            width: calc((100% / 2) - 1.25rem);
          }
        }
      }

      &-advancedSearch {
        &_wrapper {
          text-align: center;

          hr {
            border: 0;
            border-top: 1px solid @linkColor;
            position: relative;
            top: 35px;
            z-index: -1;
          }
        }
      }

      &-input {
        width: 100%;

        &.readonly {
          width: 20px;
        }
      }

      &-link {
        &_individual,
        &_personnal {
          border: 1px solid @buttonBgColor;
          color: @buttonBgColor;
          font-size: 1rem;
          line-height: 1.3rem;
          padding: 0.7rem 1rem;
          text-decoration: none;
          cursor: pointer;
        }

        &_individual {
          border-radius: 0.3rem 0 0 0.3rem;
        }

        &_personnal {
          border-radius: 0 0.3rem 0.3rem 0;
        }

        &_group {
          align-items: center;
          background-color: white;
          border: 1px solid @buttonBgColor;
          color: @buttonBgColor;
          cursor: pointer;
          display: inline-flex;
          margin: 0 0 0 20px;
          padding: 6px 20px 4px 20px;

          &:hover,
          &:focus {
            background-color: @buttonBgColor;
            color: white;

            svg {
              fill: white
            }
          }

          svg {
            fill: #1E1852;
            top: 0;
            width: 2rem;
            height: 2rem;
          }
        }
      }

      &-individual,
      &-personnal {
        &_wrapper {
          display: inline-grid;
          flex: 100%;
          max-width: 250px;
          padding: 0;
          width: auto;
        }
      }

      &-group {
        &_wrapper {
          display: none;

          @media (min-width: 580px) {
            display: block;
            padding: 0;
            text-align: right;
          }
        }
      }
    }

    &__cta {
      text-align: center;

      @media (min-width: @mobile-medium) {
        bottom: -5px;
        position: absolute;
      }

      @media (max-width: @mobile-medium) {
        margin-top: 1rem;
      }

      &-btn {
        @media (min-width: @mobile-medium) {
          max-width: 200px;
        }
      }
    }

    &__link {
      &-advanced_search {
        background-color: white;
        border: 0;
        color: @linkColor;
        cursor: pointer;
        display: inline-block;
        font-size: 1rem;
        padding: 20px;
        text-decoration: underline;
      }
    }

    &__form {
      &-individual {
        .engine__field-codeStar_wrapper,
        .engine__field-accessCodeStar_wrapper {
          display: none;
        }

        .engine__field-link_individual {
          background-color: white;
          color: white;
          background-color: @buttonBgColor;
        }

        .engine__field-link_personnal {
          background-color: transparent;
          transition: background 0.1s, border 0.1s;

          &:hover,
          &:focus {
            color: white;
            background-color: @buttonBgColor;
            opacity: 0.9;
          }
        }
      }

      &-personnal-stay {
        .engine__field-codeStar_wrapper,
        .engine__field-accessCodeStar_wrapper {
          display: block;
        }

        .engine__field-link_individual {
          background-color: transparent;
          transition: background 0.1s, border 0.1s;

          &:hover,
          &:focus {
            color: white;
            background-color: @buttonBgColor;
            opacity: 0.9;
          }
        }

        .engine__field-link_personnal {
          background-color: white;
          color: white;
          background-color: @buttonBgColor;
        }
      }
    }
  }
}
