@import './partials/_common';

// Components
@import 'node_modules/@accor/search-engine/src/styles';
@import './components/search-engine/styles';
@import './components/hera/styles';
@import './components/usp/styles';
@import './components/slider-banner/styles';
@import '../components/slick-a11y/styles';

.main-container {
    margin: 1rem auto;
    max-width: 1400px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
